import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import "./Home.css"
import { useTranslation } from 'react-i18next';

function Vcto() {
    const { t } = useTranslation();
    const { line1, line2, line3, line4, line5, line6, vctu } = t("description");
  return (
<>
<Navbar />
<main>
  <div className='MainHome'>

       <section>
        <div className='Mainabout'>

         <div className='aboutus'>{vctu.one}</div>
         <br />
         <br />
         <div className='abouonceOne'>{vctu.Two}</div>
         <br />
         <br />
         <div className='abouonce'>{vctu.Three}</div>

        </div>
        </section> 



   
  </div>
 </main>

 

<main className='vhomeIOP'>
  <div className='MainHome  '>


           <div  className='abouonceOne'>{vctu.Four}<span className='uiyji'> {vctu.Five}</span></div>

           <div id='jyhguyg' className='rytyrtugh'>
    <img className='vhome' src='/images/vhuhu.svg' /> 
    <img className='vhome2' src='/images/vhomeio.svg' /> 
          
  </div>
  </div>
 </main> 

<main className='vhomeIOP'>

  <div className='MainHome '>


           <div className='soliutioninner'>{vctu.Six} <span className='uiyji'> {vctu.Seven}</span></div>
    <br />

       
 
            <div className='Secondsection'>
              <div className='arewho ewrtfw4gf'>
                <div className='ques' >{vctu.Eight}</div>
                <div className='areinner '>{vctu.Nine}</div>
              </div>
              <div className='innerline'></div>
              <div className='arewho rtfe '>
                <div className='sene'>{vctu.Ten}</div>
                <div className='areinner'>{vctu.Eleven}</div>
              </div>
              <div className='innerline'></div>

              <div className='arewho rtfe'>
                <div className='sene'>{vctu.Twell}</div>
                <div className='areinner'>{vctu.Thiteen}</div>
              </div>
              <div className='innerline'></div>
              <div className='arewho rtfe'>
                <div className='sene'>{vctu.Fouteen}</div>
                <div className='areinner'>{vctu.Fifteen}</div>
              </div>
              <div className='innerline'></div>
              <div className='arewho rtfe'>
                <div className='sene'>{vctu.Sixteen}</div>
                <div className='areinner'>{vctu.Senteen}</div>
              </div>
              <div className='innerline'></div>

              <div className='arewho rtfe'>
                <div className='sene'>{vctu.Eiteen}</div>
                <div className='areinner'>{vctu.Ninettem}</div>
              </div>
              <div className='innerline'></div>
            </div>
            <br />

  </div>

 </main> 
<main className='vhomeIOP'>
  <div className='MainHome '>


           <div className='soliutioninner'>{vctu.Twenty}</div>
    <br />
    <br />

    <div className='rytyrtugh'>
    <img className='vhome' src='/images/vghg.svg' /> 
    <img className='vhome2' src='/images/vhome.svg' /> 
    </div> 
  </div>
 </main> 
 <a href="/contact" ><div className='letstalk'>
        <a href="/contact" className="shutter-out-horizontal"><div className='vhgv'>{line6.learn}<p className='cyfhv'><img src='/images/arrow.png' /></p></div></a>
      </div></a>
<Footer />
</>
  )
}

export default Vcto
