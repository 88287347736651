import React from 'react'
import { useTranslation } from "react-i18next";

function Some() {
    const { t } = useTranslation();
    const { line1, line2, line3, line4, line5, line6, line7 } = t("description");
  
    const { i18n } = useTranslation();
  return (
    <main>
    <div className='MainHome'>

      <section>
        <div className='Thirdsection'>
          <div>
            <div className='solutionp' >{line4.Head}</div>
            <br />
            <div className='gfhfcv'>
            <div className='soliutioninner'>{line4.headsub}</div>
            <a href='/solution'> <div className='learmore'>{line4.learn}</div></a>
          </div>
          </div>

          <br />
      
          <div className='solutions'>
           
            <img className='indus' src='/images/homeside.svg' />
            <img className='indus1' src='/images/solpop1.svg' />
{/*             
         <div className='xtyfguh'> <a href='/solution'> <div className='learmoretwo'>{line4.learn}</div></a>
         </div> */}
          </div>
        </div>
      </section>
      <br />
  
    </div>
  </main>
  )
}

export default Some
