import React, { useContext, useEffect, useRef, useState } from 'react'
import "./Navbar.css"
import { Divide as Hamburger } from 'hamburger-react'
import { useLocation, useParams} from "react-router-dom"
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom"
const languages = [
    { code: "Home", lang: "India" , Image : "/images/ind.png"  , path : "/" },
    { code: "Home", lang: "United Kingdom" , Image : "/images/uk.png"  , path : "/uk/en" },
    { code: "Home", lang: "United States" , Image : "/images/us.png" ,  path : "/us/en"  },
    { code: "Deutsch", lang: "Germany" , Image : "/images/ger.png" , path : "/de/de"   },
    { code: "Home", lang: "Australia" , Image : "/images/aus.png" ,  path : "/aus/en"  },
];
// Deutsch
function Navbar2() {
    const location = useLocation();
    const path = location.pathname;
  console.log(path , "uytdf");
    const refOneNav = useRef()
    const refdateONe = useRef()
    const refOneNavO = useRef()
    const refOneNavT = useRef()
    const refOneNavTh = useRef()
    const refOneNavFo = useRef()
    const {t} = useTranslation();
    const {line1, line2} = t("description");
  
    const { i18n } = useTranslation();

    const refdate = useRef(null)
    const [isOpen, setOpen] = useState(false)
    const [navbar, setNavbar] = useState(false)
    const [Toggle, setToggle] = useState(false)

    useEffect(() => {
        document.body.dir = i18n.dir();
        if(path === "/about_g"){
            refOneNav.current.classList.add("ytedrufyt6")
        }else if(path === "/solution_g"){
            refOneNavO.current.classList.add("ytedrufyt6")
        }else if(path === "/consulting_g"){
            refOneNavT.current.classList.add("ytedrufyt6")
        }else if(path === "/ourprocess_g"){
            refOneNavTh.current.classList.add("ytedrufyt6")
        }else if(path === "/industryg_g"){
            refOneNavFo.current.classList.add("ytedrufyt6")
        }
    }, [i18n, i18n.language]);

    const changeLanguage = (lng) => {
        setToggle(false)
        i18n.changeLanguage(lng);
        // refdate.current.classList.remove("finghgvh")
        // alert("ghjgk")
    };




    const langdata = () => {
       
        setToggle(true)
        refdate.current.classList.toggle("finghgvh")
    }
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground)



    return (
        <>
            <nav >
                <div className="Navbar2">
                    <div>
                        <div className='logohead'>
                            <a href='/'>    <img src='/images/b1.svg' alt='NTTL' /> </a>
                        </div>
                    </div>
                    <div>
                        <div className='NavCollection'>
                           
                        <div className="pages" ref={refOneNav} ><Link to="/about_g" >{line1.line}  </Link></div>
                                <div className="pages" ref={refOneNavO} ><Link to="/solution_g" >{line1.line1}  </Link></div>
                                <div className="pages" ref={refOneNavT} ><Link to="/consulting_g" >{line1.line2}  </Link></div>
                                <div className="pages" ref={refOneNavTh} ><Link to="/ourprocess_g" >{line1.line3}  </Link></div>
                                <div className="pages" ref={refOneNavFo} ><Link to="/industry_g" >{line1.line4}  </Link></div>
                            <a href="/contact_g"> <div className='connect' >{line1.line5}</div></a>
                              

                               <div className='linehead'>
                                <div className='lineinter'></div>
                            </div>
                            <div className='languagelogo' onClick={langdata}>
                                <img src='/images/lang.svg' alt='NTTL' />
                            </div>

                            <div className='lineheadTwo'>
                                <div className='lineinter'></div>
                            </div>
                            <div className='hamburger' >
                                <Hamburger toggled={isOpen} toggle={setOpen} />
                            </div>
                        </div>

                    </div>

                </div>

            </nav>
<div className='yufgv'>
            <div className={Toggle ? "dropn" : "dropnewf"} ref={refdate}>
                <div>
              
                    {languages.map((lng) => {
                        return (
                            <Link to={lng.path}> <div className='lank'>
                            <div 
                                className={lng.code === i18n.language ? "selected" : ""}
                                key={lng.code}
                                onClick={() => changeLanguage(lng.code)}
                            >
                               

                                <div className='cgfhfv'><div className='ytfghv'><img src={lng.Image}/></div> {lng.lang}</div>
                        <div className='cgfhfvwegd'>
                            {/* <div className='cghvj'>Home</div> */}
                            {/* <Link to={lng.path}> <div className='cghvj' >{lng.code}</div> </Link>  */}
                        </div>
                                </div>
                                </div></Link>  
                        );
                    })}
                 

                </div>
                </div>
            </div>
<div className='jyhg'>
            <div className={isOpen ? "Menu" : "Menutwo"}>
                <div className='MenuHeade'>
                    <div className='MenuInnter'>
                        <div className='menulogo'>
                            <img src='/images/b01.svg' />
                        </div>
                        <div className='MenuIteam'>
                            <div className='languagelogo' onClick={langdata}>
                                <img src='/images/footerlang.png' alt='NTTL' />
                            </div>
                            <div className='lineheadTwo'>
                                <div className='lineinterTwo'></div>
                            </div>
                            <div className='hamburger' >
                                <Hamburger toggled={isOpen} toggle={setOpen} />
                            </div>
                        </div>
                    </div>
                    <div className='Menucolle'>
                    <a href='/about_g'><div className='pagesTwo'>About</div></a>
                    <a href='/solution_g'> <div className='pagesTwo'>Solutions</div></a>
                    <a href='/consulting_g'><div className='pagesTwo'>Consulting</div></a>
                    <Link to='/ourprocess_g'>   <div className='pagesTwo'>Our Process</div></Link>
                        <a href="/industry_g"> <div className='pagesTwo'>Industries</div></a>
                        <a href="/contact_g">  <div className='connectTwo pagesTwo'>Let's Connect</div></a>
                       {/* <a href='https://wa.me/9198000 98000'> <div className='pagesTwo' >+91 98000 98000</div></a> */}
                       <a href="tel:+94888 77899"> <div className='pagesTwo' >+91 98000 98000</div></a>
                        <a href="mailto:hello@chocohugz.in" ><div className='pagesTwo'>info@newtabtechlab.com</div></a>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Navbar2
