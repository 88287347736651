import React from 'react'
import "./About.css"
import { useTranslation } from "react-i18next";
import Navbar from '../Navbar/Navbar';
import Footer2 from '../Footer/Footer2';
import Footer from '../Footer/Footer';
import { Link, useParams } from 'react-router-dom';

function About() {
  const {t} = useTranslation();
  const { line6 ,About } = t("description");
  const paramswrgt = useParams()

  console.log(paramswrgt.id  , "jhyrfytuih");
  return (
<>
<Navbar />

<main>
  <div className='MainHome'>

       <section>
        <div className='Mainabout'>

         <div className='aboutus'>{About.head}</div>
         <br />
         <div className='abouonce'>{About.sub1}</div>
         <br />
         <br />
  
         <div className='brandAbout'>{About.sub4}</div>
   
         {/* <div className='abouonce'>{About.sub5}</div> */}
         
         <br />
         <br />
         <div className='aboutbrand'>
    
            <img className='hgtfyf1' src='/images/aboutnew.svg' />
            <img className='hgtfyf' src='/images/aboutnew1.svg' />
         </div>





         <div className='ghj' >
            <div className='divin'>{About.sub9}</div>
         </div>
        </div>
        </section> 



   
  </div>
 </main>
 <Link to="/contact" ><div className='letstalk'>
 <a href="/contact" className="shutter-out-horizontal"><div className='vhgv'>{line6.learn}<p className='cyfhv'><img src='/images/arrow.png' /></p></div></a> 
 </div></Link>
 
<Footer />

</>
  )
}

export default About
