import React, { useEffect } from 'react'
import "./Home.css"
import { useTranslation } from "react-i18next";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import Some from './Some';

function Home4() {
  const { t } = useTranslation();
  const { line1, line2, line3, line4, line5, line6, line7 } = t("description");

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <Navbar />
      <main>
        <div className='MainHome'>

          <section>
            <div>
              <div className='Title'>
                <div className='titleinner'>
                  
                  {line2.head}<br />
                  {line2.head2} <span className='HeadText'>{line2.sub1}<br />
                   {line2.sub11}</span> {line2.sub01}<br />
                   {line2.sub2}
                </div>
              </div>
              
             <a href='/contact'> <div className='Connect'>
              {line1.line5}
                </div></a>

            </div>
          </section>

        </div>
      </main>

      <div className='lineheadTwoHome HEadliolo'>
        <div className='lineinterhome'></div>
      </div>

      <main>
        <div className='MainHome'>

        <section>
            <div className='Secondsection'>
              <div className='arewho '>
                <div className='ques' >{line3.head1}{line3.Question1}</div>
                <div className='areinner'>{line3.inner1.one}</div>
              </div>
              <div className='innerline'></div>
              <div className='arewho rtfe'>
                <div className='sene'>{line3.head2}{line3.Question2}</div>
                <div className='areinner'>{line3.inner1.two}</div>
              </div>
              <div className='innerline'></div>

              <div className='arewho rtfe'>
                <div className='sene'>{line3.head3}{line3.Question3}</div>
                <div className='areinner'>{line3.inner1.three}</div>
              </div>
              <div className='innerline'></div>
              <div className='arewho rtfe'>
                <div className='sene'>{line3.head4}{line3.Question4}</div>
                <div className='areinner'>{line3.inner1.four}</div>
              </div>
              <div className='innerline'></div>
              <div className='arewho rtfe'>
                <div className='sene'>{line3.head5}{line3.Question5}</div>
                <div className='areinner'>{line3.inner1.five}</div>
              </div>
              <div className='innerline'></div>

              <div className='arewho rtfe'>
                <div className='sene'>{line3.head6}{line3.Question6}</div>
                <div className='areinner'>{line3.inner1.six}</div>              </div>
              {/* <div className='innerline'></div> */}
            </div>
          </section>
          <br />
        </div>
      </main>
      <div className='lineheadTwoHome yturf7yug'>
        <div className='lineinterhome'></div>
      </div>
      <main>
        <div className='MainHome'>

          <section>
            <div className='Fouthsection'>
             <div className='newcontent'>
          {line2.SubTwo.One}<span className='builduo'> {line2.SubTwo.Two}</span>  <span className='yr76f'> {line2.SubTwo.Three}</span> {line2.SubTwo.Four}<span className='builduo'> {line2.SubTwo.Five}</span>
             </div>
             <a href='/contact'>  <div className='connecttwo'>
             {line1.line5}
             </div></a>
            </div>
          </section>

        </div>
      </main>

      <div className='lineheadTwoHome yturf7yug'>
        <div className='lineinterhome'></div>
      </div>

<Some />
      <div className='lineheadTwoHome'>
        <div className='lineinterhome'></div>
      </div>

      {/* <main>
        <div className='MainHome'>

          <section>
            <div className='Fouthsection'>
              <div className='industry'>
                <div>
                  <div className='solutionp'>{line5.Head}</div>
                  <br />
                  <div className='vghfg'>{line5.headsub}</div>
                  <br />
                  <div className='indus'><img src='/images/indus.svg' /></div>
                </div>
                <div>
                  <div className='ideastar'>{line5.sol.one}</div>
                  <div className='linekoko'></div>
                  <div className='ideastar'>{line5.sol.two}</div>
                  <div className='linekoko'></div>
                  <div className='ideastar'>{line5.sol.three}</div>
                  <br />
                  <a href='/industry'> <div className='talkus'>{line5.learn}</div> </a>
                </div>
              </div>
            </div>
          </section>

        </div>
      </main>

      <div className='lineheadTwoHome'>
        <div className='lineinterhome'></div>
      </div> */}


{/* 
      <main>
        <div className='MainHome'>

          <section>
            <div className='Fouthsection'>

              <div>
                <div className='buhead'>
                  <div className='solutionp'>{line7.one}</div>
                </div>
                <br />
                <br />
                <div className='bundles'>
                  <div className='bundleinner'>
                    <div className='hgfgv huihu'>{line7.one}</div>
                    <br />
                    <div className='hgfgv'>Lorem ipsum dolor sit amet</div>
                    <br />
                    <div className='linek'></div>

                    <div className='hgfgvewdag'>
                      <ul>
                        <li>Up to 15 developers</li>
                        <li>Up to 100 services</li>
                        <li>Unlimited rollouts </li>
                        <li>Single sign-on </li>
                        <li> Slack & email support</li>
                      </ul>
                    </div>
                    <br />


                    <a href='/contact'>  <div className='schedule'>{line7.five}</div></a>
                    <br />
                    <br />
                  </div>
                  <div className='bundleinner'>
                    <div className='hgfgv huihu'>{line7.three}</div>
                    <br />
                    <div className='hgfgv'>Lorem ipsum dolor sit amet</div>
                    <br />
                    <div className='linek'></div>

                    <div className='hgfgvewdag'>
                      <ul>
                        <li>Up to 15 developers</li>
                        <li>Up to 100 services</li>
                        <li>Unlimited rollouts </li>
                        <li>Single sign-on </li>
                        <li> Slack & email support</li>
                      </ul>
                    </div>
                    <br />


                    <a href='/contact'>   <div className='schedule'>{line7.two}</div></a>

                    <br />
                    <br />
                  </div>
                  <div className='bundleinner'>
                    <div className='hgfgv huihu'>{line7.four}</div>
                    <br />
                    <div className='hgfgv'>Lorem ipsum dolor sit amet</div>
                    <br />
                    <div className='linek'></div>

                    <div className='hgfgvewdag'>
                      <ul>
                        <li>Up to 15 developers</li>
                        <li>Up to 100 services</li>
                        <li>Unlimited rollouts </li>
                        <li>Single sign-on </li>
                        <li> Slack & email support</li>
                      </ul>
                    </div>
                    <br />


                    <a href='/contact'>  <div className='schedule'>{line7.five}</div></a>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </main> */}
      {/* <br></br>
      <br></br>
      <div className='lineheadTwoHome'>
        <div className='lineinterhome'></div>
      </div> */}
      <main>

        <div className='MainHome'>

          <section>
            <div className='Fithsection'>
              <div>
                <div className='brandgu'>{line6.Head}</div>
                <br />
                <div className='Brandhuhu'>{line6.headsub}</div>
                <br />
                <br />
                <br />
                <div className='nttl'>
      
                  <div className='huhum' >
                    <div className='brand2'><img src='/images/b2.svg' /></div>
                  </div>
                  <div className='huhum' >
                    <div className='brand3' ><img src='/images/b3.svg' /></div>
                  </div>
                </div>
              </div>

            </div>
          </section>

        </div>
      </main>

      <a href="/contact" ><div className='letstalk'>
        <a href="/contact" className="shutter-out-horizontal"><div className='vhgv'>{line6.learn}<p className='cyfhv'><img src='/images/arrow.png' /></p></div></a>
      </div></a>

      <Footer />
    </>
  )
}

export default Home4

