import React, { useEffect } from 'react'
import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Navbar from './Component/Navbar/Navbar'
import Footer from './Component/Footer/Footer'
import Home from './Component/LandingPage/Home'
import Home2 from './Component/LandingPage/Home2'
import About from './Component/About/About'
import Solution from './Component/Solution/Solution'
import Consulting from './Component/Consulting/Consulting'
import Industry from './Component/Industry/Industry'
import Contact from './Component/Contact/Contact/Contact'
import Process from './Component/Process/Process/Process'
import Navbar2 from './Component/Navbar/Navbar2'
import Home3 from './Component/LandingPage/Home3'
import Home4 from './Component/LandingPage/Home4'
import Home5 from './Component/LandingPage/Home5'
import About1 from './Component/About/About1'
import Consulting1 from './Component/Consulting/Consulting1'
import Solution1 from './Component/Solution/Solution1'
import Contact1 from './Component/Contact/Contact/Contact1'
import Process1 from './Component/Process/Process/Process1'
import Industry1 from './Component/Industry/Industry1'
import Vcto from './Component/LandingPage/Vcto'
function App() {

  const setHeight =()=>{
    const currentheight = window.innerHeight;
    document.body.style.height = `${currentheight}px`
  };
  window.addEventListener("resize" , setHeight)


  useEffect(() => {

    setHeight()
//     const handleContextmenu = e => {
//         e.preventDefault()
//     }
//     document.addEventListener('contextmenu', handleContextmenu)
//     return function cleanup() {
//         document.removeEventListener('contextmenu', handleContextmenu)
//  }
},[ ])


  return (
    <>
    <div className='Headerdiv'>
      <BrowserRouter>
        <Routes>
     
          <Route path='/consulting_g' element={<Consulting1 />}></Route>
          <Route path='/solution_g' element={<Solution1 />}></Route>
          <Route path='/contact_g' element={<Contact1 />}></Route>
          <Route path='/ourprocess_g' element={<Process1 />}></Route>
          <Route path='/industry_g' element={<Industry1 />}></Route>
          <Route path='/about_g' element={<About1 />}></Route>
          <Route path='/uk/en' element={<Home3 />}></Route>
          <Route path='/us/en' element={<Home4 />}></Route>
          <Route path='/aus/en' element={<Home5 />}></Route>
          <Route path='/de/de' element={<Home />}></Route>
          <Route path='/vcto' element={<Vcto />}></Route>
          {/* <Route path='/' element={<Home3 />}></Route> */}
          <Route path='/' element={<Home2 />}></Route>
          <Route path='/nav' element={<Navbar />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/solution' element={<Solution />}></Route>
          <Route path='/consulting' element={<Consulting />}></Route>
          <Route path='/industry' element={<Industry />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/ourprocess' element={<Process />}></Route>
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
      </div>
    </>
  )
}

export default App
