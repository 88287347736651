import React from 'react'
import '../Process/Process.css'
import { useTranslation } from "react-i18next";
import Navbar from '../../Navbar/Navbar';
import Footer2 from '../../Footer/Footer2';
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';

const Process = () => {
     const {t} = useTranslation();
     const { line6 ,process } = t("description");
   
  return (
    <>
    <Navbar />
    <main>
       <div className='pro1'>
           
       <div className='pro3'>
           <div className='pro31'>
               <div className='ytukjgb'>{process.one}</div>
               <div className='vkjkgjb'><img src='/images/sol1.svg' /></div>
           </div>
    
           <div className='pro32 ytukjgb'>{process.two}</div>
           <div className='pro33' id='pro33'><img src='/images/pro33.svg'></img></div>
           <div className='pro33' id='pro34'><img src='/images/pro44.svg'></img></div>
       </div>
<br />
<br />
<br />

       <div className='pro5'>
       <div className='pro31'>
          <div>{process.three}</div>
          <div className='vyjukilkhgb'><img src='/images/con1.svg' /></div>
       </div>

       </div>

       <div className='pro6'>
       <div className='pro61' id='pro33'><img src='/images/pro313.svg'></img></div>
       <div className='pro61' id='pro34'><img src='/images/pro414.svg'></img></div>


       </div>

       <div className='pro7'>
            <div className='pro71'>{process.five}</div>
            <br />
       </div>
       </div>
  </main>
  <Link to="/contact" ><div className='letstalkprocess'>
 <a href="/contact" className="shutter-out-horizontal"><div className='vhgv'>{line6.learn}<p className='cyfhv'><img src='/images/arrow.png' /></p></div></a> 
 </div></Link>
 
<Footer />

  </>
  )
}

export default Process