import React, { useContext, useEffect, useRef, useState } from 'react'
import "./Navbar.css"
import { Divide as Hamburger } from 'hamburger-react'
import { useTranslation } from "react-i18next";
import {Link, useLocation, useParams} from "react-router-dom"
const languages = [
    { code:[ "Home"], lang: "India" , Image : "/images/ind.png"  , path : "/" },
    { code: ["Home"], lang: "United Kingdom" , Image : "/images/uk.png"  , path : "/uk/en" },
    { code: ["Home"], lang: "United States" , Image : "/images/us.png" ,  path : "/us/en"  },
    { code: ["Deutsch"],lang: "Germany" , Image : "/images/ger.png" , path : "/de/de"   },
    { code: ["Home"], lang: "Australia" , Image : "/images/aus.png" ,  path : "/aus/en"  },
];
// Deutsch
function Navbar() {
    const location = useLocation();
    const path = location.pathname;
  console.log(path , "uytdf");
    const refOneNav = useRef()
    const refdateONe = useRef()
    const refOneNavO = useRef()
    const refOneNavT = useRef()
    const refOneNavTh = useRef()
    const refOneNavFo = useRef()
    const refOneNavct = useRef()

    const {t} = useTranslation();
    const {line1, line2} = t("description");
  
    const { i18n } = useTranslation();

    const refdate = useRef(null)
    const [isOpen, setOpen] = useState(false)
    const [navbar, setNavbar] = useState(false)
    const [Toggle, setToggle] = useState(false)
const [four , setfour] = useState([])


    useEffect(() => {
     
        document.body.dir = i18n.dir();

        if(path === "/about"){
            refOneNav.current.classList.add("ytedrufyt6")
        }else if(path === "/solution"){
            refOneNavO.current.classList.add("ytedrufyt6")
        }else if(path === "/consulting"){
            refOneNavT.current.classList.add("ytedrufyt6")
        }else if(path === "/ourprocess"){
            refOneNavTh.current.classList.add("ytedrufyt6")
        }else if(path === "/industry"){
            refOneNavFo.current.classList.add("ytedrufyt6")
        }else if(path === "/vcto"){
            refOneNavct.current.classList.add("ytedrufyt6")
        }

    }, [i18n, i18n.language]);

    const changeLanguage = (lng) => {
        setToggle(false)
        i18n.changeLanguage(lng);
        // refdate.current.classList.remove("finghgvh")
        // alert("ghjgk")
    };




    const langdata = () => {
       
        setToggle(true)
        refdate.current.classList.toggle("finghgvh")
    }

    const gqgwejdfu = ()=>{
        refdateONe.current.classList.toggle("Menutwo")
        // alert("ghjc")

    }
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground)


    const navbardata = [
        {
           path : "/about",
           name  : line1.line
        },
        {
            path : "/solution",
            name  : line1.line1
        },
        {
            path : "/consulting",
            name  : line1.line2
        },
        {
            path : "/ourprocess",
            name  : line1.line3
        },
        {
            path : "/industry",
            name  : line1.line4
        },
     
    ]


    return (
        <>
            <nav >
                <div className="Navbar">
                    <div>
                        <div className='logoheadONe'>
                            <a href='/'>    <img src='/images/logo.svg' alt='NTTL' /> </a>
                        </div>
                    </div>
                    <div>
                        <div className='NavCollection'>
                   
                 
                                <div className="pages" ref={refOneNavct} ><Link to="/vcto" >vCTO</Link></div>
                                <div className="pages" ref={refOneNav} ><Link to="/about" >{line1.line}  </Link></div>
                                <div className="pages" ref={refOneNavO} ><Link to="/solution" >{line1.line1}  </Link></div>
                                <div className="pages" ref={refOneNavT} ><Link to="/consulting" >{line1.line2}  </Link></div>
                                <div className="pages" ref={refOneNavTh} ><Link to="/ourprocess" >{line1.line3}  </Link></div>
                                <div className="pages" ref={refOneNavFo} ><Link to="/industry" >{line1.line4}  </Link></div>
                              

                            <Link to="/contact"> <div className='connect' >{line1.line5}</div></Link>
                            <div className='linehead'>
                                <div className='lineinter'></div>
                            </div>
                            <div className='languagelogo' onClick={langdata}>
                                <img src='/images/lang.svg' alt='NTTL' />
                            </div>

                            <div className='lineheadTwo'>
                                <div className='lineinter'></div>
                            </div>
                            <div className='hamburger' onClick={gqgwejdfu}>
                                <Hamburger toggled={isOpen} toggle={setOpen}  />
                            </div>
                        </div>

                    </div>

                </div>

            </nav>
            <div className='yufgv'>
            <div className={Toggle ? "dropn" : "dropnewf"} ref={refdate}>
                <div className='tyfugh'>
              
                    {languages.map((lng) => {
                        return (
                            <Link to={lng.path}> <div className='lank'>
                            <div 
                                className={lng.code === i18n.language ? "selected" : ""}
                                key={lng.code}
                                onClick={() => changeLanguage(lng.code)}
                            >
                               

                                <div className='cgfhfv'><div className='ytfghv'><img src={lng.Image}/></div> {lng.lang}</div>
                        <div className='cgfhfvwegd'>
                            {/* <div className='cghvj'>Home</div> */}
                            {/* <Link to={lng.path}> <div className='cghvj' >{lng.code.join(' ')}</div> </Link>  */}
                        </div>
                                </div>
                                </div></Link>  
                        );
                    })}
                 

                </div>
                </div>
            </div>

            <div className={isOpen ? "Menu" : "Menutwo"}  ref={refdateONe}>
                <div className='MenuHeade'>
                    <div className='MenuInnter'>
                        <div className='menulogo'>
                            <img src='/images/footerlogo.png' />
                        </div>
                        <div className='MenuIteam'>
                            <div className='languagelogo' onClick={langdata}>
                                <img src='/images/lang.svg' alt='NTTL' />
                            </div>
                            <div className='lineheadTwo'>
                                <div className='lineinterTwo'></div>
                            </div>
                            <div className='hamburger' onClick={gqgwejdfu}>
                                <Hamburger toggled={isOpen} toggle={setOpen} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='hgyhrfuthig'></div>

                    <div className='Menucolle'>
                    <a href='/vcto'><div className='pagesTwo'>vCTO</div></a>
                    <br />
                    <div className='hgyhrfuthig'></div>
                    <a href='/about'><div className='pagesTwo'>About</div></a>
                    <br />
                    <div className='hgyhrfuthig'></div>
                    <a href='/solution'> <div className='pagesTwo'>Solutions</div></a>
                    <br />
                    <div className='hgyhrfuthig'></div>
                    <a href='/consulting'><div className='pagesTwo'>Consulting</div></a>
                    <br />
                    <div className='hgyhrfuthig'></div>
                    <Link to='/ourprocess'>   <div className='pagesTwo'>Our Workflow</div></Link>
                    <br />
                    <div className='hgyhrfuthig'></div>
                        <a href="/industry"> <div className='pagesTwo'>Industries</div></a>
                        <br />
                    <div className='hgyhrfuthig'></div>
                        <Link to="/contact">  <div className='connectTwo pagesTwo'>Let's Connect</div></Link>
                        {/* <a href='https://wa.me/9198000 98000'> <div className='pagesTwo' >+91 98000 98000</div></a> */}
                        {/* <a href="tel:+94888 77899"> <div className='pagesTwo' >+91 98000 98000</div></a>
                        <a href="mailto:hello@chocohugz.in" ><div className='pagesTwo'>info@newtabtechlab.com</div></a> */}
                    </div>
                </div>
            </div>


      
        </>
    )
}

export default Navbar
