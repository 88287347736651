import React from 'react'
import "./Solution.css"
import { useTranslation } from "react-i18next";
import Navbar from '../Navbar/Navbar';
import Footer2 from '../Footer/Footer2';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

function Solution() {
  const {t} = useTranslation();
  const { line6 ,Solution } = t("description");

  return (
  <>
<Navbar />
 <main>
  <div className='MainHome'>

       <section>
           <div className='Thirdsectionsolution'>
             <div>
              <div className='aboutus'>{Solution.head}</div>
              <br />
              <div className='Solutoin'>{Solution.sub1}</div>
             </div>
             <br />
             <br />
     
  
             <div className='solutionsPage'>
             <img className='indus' src='/images/solpop.svg' />
             <img className='indus1' src='/images/solpop1.svg' />
             </div>
          
           
        
             <a href='/ourprocess'><div className='ghjb'>
           <div className='seeourprocess'>{Solution.sub20}</div>
             </div></a>
           </div>
        </section> 
 
     
  </div>
 </main>
 <Link to="/contact" ><div className='letstalk'>
 <a href="/contact" className="shutter-out-horizontal"><div className='vhgv'>{line6.learn}<p className='cyfhv'><img src='/images/arrow.png' /></p></div></a> 
 </div></Link>
 
<Footer />

 
  </>
  )
}

export default Solution
