import React from 'react'
import "./About.css"
import { useTranslation } from "react-i18next";
import Navbar from '../Navbar/Navbar';
import Footer2 from '../Footer/Footer2';
import Navbar2 from '../Navbar/Navbar2';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

function About1() {
  const {t} = useTranslation();
  const { line6 ,About } = t("description");

  return (
<>
<Navbar2 />

<main>
  <div className='MainHome'>

       <section>
        <div className='Mainabout'>
         <div className='aboutus'>{About.head}</div>
         <br />
         <div className='abouonce'>{About.sub1}</div>
         <br />
         <div className='abouonce'>{About.sub2}</div>
         <br />
         <div className='abouonce'>{About.sub3}</div>
         <br />
         <br />
         <br />
         <div className='brandAbout'>{About.sub4}</div>
         <br />
         <div className='abouonce'>{About.sub5}</div>
         
         <br />
         <br />
         <div className='aboutbrand'>
            <div className='vghb'> 
            <div className='jghfkvioi'>
            <div className='aboutimage'><img src='/images/b1.svg' />
            </div>
            </div>
            <br />
            <div className='cghfg sideabout'>{About.sub6}</div>
            <br />
          
            </div>
            <div className='tdfg'> 
        <div className='hggyfug'>
        <div className='jghfkvioi'>
        <div className='aboutimahe2'><img src='/images/b2.png' />
        </div>
        </div>
            <br />
            <div className='sideabout'>{About.sub7}</div>
            <br />
        </div>
            </div>
           
            <div className='sahgrfset'>
                <div className='hggyfug'>
                <div className='jghfkvioi'>
            <div className='aboutimage3'><img src='/images/b3.png' />
            </div>
            </div>
            <br />
       
            <div className='sideabout'>{About.sub8}</div>
            <br />
            
            </div>
            </div>
         </div>
<br />
<br />
<br />
<br />


         <div className='ghj' >
            <div className='divin'>{About.sub9}</div>
         </div>
        </div>
        </section> 
       <br />


   
  </div>
 </main>
 <Link to="/contact" ><div className='letstalk'>
 <a href="/contact" className="shutter-out-horizontal"><div className='vhgv'>{line6.learn}<p className='cyfhv'><img src='/images/arrow.png' /></p></div></a> 
 </div></Link>
 
<Footer2 />

</>
  )
}

export default About1
