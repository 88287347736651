import React, { useRef, useState } from 'react'
import "./Footer.css"
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { ReactComponent as MySVG } from "./up.svg";
const languages = [
  // { code:[ "Home"], lang: "India" , Image : "/images/ind.png"  , path : "/ind" },
  { code: "Home", lang: "United Kingdom" , Image : "/images/uk.png"  , path : "/uk/en" },
  { code: "Home", lang: "United States" , Image : "/images/us.png" ,  path : "/us/en"  },
  { code: "Deutsch", lang: "Germany" , Image : "/images/ger.png" , path : "/de/de"   },
  { code: "Home", lang: "Australia" , Image : "/images/aus.png" ,  path : "/aus/en"  },
];

function Footer2() {
  const [Toggle, setToggle] = useState(false)
  const refdate = useRef(null)
  const { i18n } = useTranslation();

  const {t} = useTranslation();
  const {footer } = t("description");
  
  const changeLanguage = (lng) => {
    setToggle(false)
    i18n.changeLanguage(lng);
    // refdate.current.classList.remove("finghgvh")
    // alert("ghjgk")
};

const langdata = () => {
  setToggle(true)
  refdate.current.classList.toggle("rgtf")
}

  return (
    <>
    <footer>
    <ScrollToTop id='scrolkjo'  smooth 
  />

      <div className='footer'>
        <div className='footerinner'>
          <a href='/'><div className='footerlogo'>
            <img src='/images/footerlogo.png' alt='NTTL' />
          </div></a>
          <div className='footerinput' onClick={langdata}>
            <div className='lan'>UK - English</div>
            <div className='Footerlan' >
              <img src='/images/footerlang.png' alt='Lang' />
            </div>
          </div>
          <div className='yufgvFooter'>
          <div className={Toggle ? "jkjirfdgv" : "rgtf"} ref={refdate} >
                <div>
                 
                {languages.map((lng) => {
                        return (
                            <a href={lng.path}> <div className='lankwera4gt'>
                            <div 
                                className={lng.code === i18n.language ? "selected" : ""}
                                key={lng.code}
                                onClick={() => changeLanguage(lng.code)}
                            >
                               

                                <div className='cgfhfv'><div className='ytfghv'><img src={lng.Image}/></div> {lng.lang}</div>
                        <div className='cgfhfvwegd'>
                            {/* <div className='cghvj'>Home</div> */}
                            {/* <a href={lng.path}> <div className='cghvj' >{lng.code}</div> </a>  */}
                        </div>
                                </div>
                                </div></a>  
                        );
                    })}

                </div>
                </div>
            </div>
        </div>
        <div className='footerinfo'>
            <div className='rightinfo'>
              <div className='headfooter'>{footer.foo2}</div>
              <br />
     
              {/* <a href="tel:+98000 98000"> <div className='sidefoo'>+91 98000 98000</div></a>
              <br /> */}
              <a  href="mailto:info@newtabtechlab.com">  <div className='sidefoo'>hello@newtabtechlab.com</div></a>
            </div>
            <div className='center footerinfohun'>
              <div className='headfooter'>{footer.foo3}</div>
              <br />
            <a href='/vcto'>  <div className='colllection1'>vCTO</div></a>
            <a href='/about'>  <div className='colllection'>{footer.foo4}</div></a>
            {/* <a href='/'><div className='colllection'>{footer.foo5}</div></a> */}
            <a href='/consulting'><div className='colllection'>{footer.foo6}</div></a>
            <a href='/industry'> <div className='colllection'>{footer.foo7}</div></a>
            <a href='/solution'><div className='colllection'>{footer.foo8}</div></a>
            <a href='/ourprocess'> <div className='colllection'>{footer.foo9}</div></a>
            <a href='/ourprocess'> <div className='colllection'>FAQ</div></a>
            </div>
            <div className='footerinfohun'>
              <div className='headfooter'>{footer.foo10}</div>
              <br />
              <div className='colllection1'>LinkedIn</div>
              <a href='/'><div className='colllection'>x</div></a>

              <a href='/'> <div className='colllection'>{footer.foo12}</div></a>
              <a href='/'> <div className='colllection'>Facebook</div></a>
            </div>
            {/* <div className='footerinfohun'>
              <div className='headfooter'>Country Sites</div>
              <br />
              <a href='/'> <div className='colllection1'>India-Eng</div></a>
              <a href='/uk/en'><div className='colllection'>United Kingdom-Eng</div></a>
              <a href='/us/en'> <div className='colllection'>United States-Eng</div></a>
              <a href='/de/de'> <div className='colllection'>Germany-Deutsch & Eng</div></a>
              <a href='/aus/en'> <div className='colllection'>Australia-Eng</div></a>

            </div> */}
        
        </div>
        <br />
        <br />
        <br />
        <br />
        
      </div>
  
    </footer>
    <div className='jhgvf'> 
  <div className='MainHomefooter'>

    <div className='addres'>Registered Address : 1/146, Bettatti, Yedapalli Post, Coonoor, The Nilgiris, Tamilnadu, 643104 - IN.</div>
       
  </div>
 </div> 
    <div className='Footerline'></div>
    <div className='sitema'>
    <div className='siteinner'>
         
         <div className='sidefoo hjyjfv'>{footer.foo14}</div>
         <div className='sidefoo1'>
           <div className='hjyjfv' >{footer.foo16} </div>
           <div  className='sidefoo2'></div>
           <div className='hjyjfv'>{footer.foo15}</div>

         </div>
       
       
       </div>


          </div>
          
    </>
  )
}

export default Footer2
