import React from 'react'
import "../Solution/Solution.css"
import { useTranslation } from "react-i18next";
import Navbar from '../Navbar/Navbar';
import Footer2 from '../Footer/Footer2';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

function Consulting() {
  const {t} = useTranslation();
  const { line6 ,Consulting } = t("description");

  return (
  <>
   <Navbar />

 <main>
  <div className='MainHome'>

       <section>
           <div className='Thirdsectionsolution'>
             <div>
              <div className='aboutus'>{Consulting.head}</div>
              <br />
              <div className='Solutoin'>{Consulting.sub1}</div>
             </div>
             <br />
             <br />
  
          
       
             <div className='solutionsPage'>
         <img className='indus' src='/images/consulo.svg' />
         <img className='indus1' src='/images/consulo1.svg' />
          
           
              </div>


     <a href='/ourprocess'>    <div className='ghjbcon'>
          <div className='seeourprocess'>{Consulting.sub11}</div>
             </div></a>
           </div>
        </section> 

   
     
  </div>
 </main>
 <Link to="/contact" ><div className='letstalk'>
 <a href="/contact" className="shutter-out-horizontal"><div className='vhgv'>{line6.learn}<p className='cyfhv'><img src='/images/arrow.png' /></p></div></a> 
 </div></Link>
 
 <Footer />

  </>
  )
}

export default Consulting
